<template>
  <div id='login'>
    <div class="login_box">
      <div class="login_avatar"><img src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif"></div>
      <el-form :model="loginForm" :rules="loginFromRules" ref="loginFormRef" class="login_form">
        <el-form-item prop="name">
          <el-input v-model="loginForm.name" placeholder="请输入用户名" clearable><i slot="prefix" class="el-input__icon el-icon-user"></i></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input v-model="loginForm.pwd" placeholder="请输入密码" show-password clearable><i slot="prefix" class="el-input__icon el-icon-lock"></i></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button @click="login" type="primary">登录</el-button>
          <el-button @click="resetLoginForm" type="info">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getLogin} from '@api';
import {sha256} from 'js-sha256';
import {setCookie, getCookie} from "../../utils/utils";

export default {
  data () {
    return {
      loginForm: { // 双向绑定
        name: 'admin',
        pwd: '123456',
      },
      loginFromRules: { // 验证规则
        name: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        pwd: [{required: true, message: '请选择密码', trigger: 'blur'}]
      },
      isLogin: false,
      pathName: '', // 登录前的页面
    }
  },
  created() {
    this.checkIsLogin();
  },
  methods:{
    checkIsLogin() {
      const token = this.$store.state.token;
      if (token) {
          this.isLogin = true;
          this.$router.replace({name: 'Home'})
      }
    },
    login() { // 登录
      const {isLogin, pathName} = this;
      if(isLogin) return;
      this.$refs.loginFormRef.validate(valid => {
        if(!valid) return;
        const timestamp = this.$moment().unix();
        const params = {...this.loginForm, timestamp};
        params.sign = sha256.hmac(params.pwd, Object.values(params).join(''));
        getLogin(params).then(res => {
            /*
            * 1、获取登入数据
            * 2、将token存入cookies
            * 3、将token存入vuex
            * */
          this.isLogin = true;
          this.$message.success('登录成功！');
          const {access_token, refresh_token, info} = res.data;
          setCookie('token', access_token, 7200);
          setCookie('refresh_token', refresh_token, 3600 * 24 * 7);
          setCookie('user_info', JSON.stringify(info), 7200);
          this.$store.commit('setToken', access_token);
          this.$store.commit('setRefreshToken', refresh_token);
          this.$store.commit('setUserInfo', info);
          if(pathName) return setTimeout(() => this.$router.replace({name: pathName}), 1000);
          setTimeout(() => this.$router.replace({name: "Home"}), 1000);
        });
      })
    },
    resetLoginForm() { // 重置表单
      this.$refs.loginFormRef.resetFields();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.pathName = from.name);
  }
}
</script>

<style lang='less' scoped>
#login {
  width: 100%;
  height: 100%;
  background:	#008B8B;

  .login_box {
    width: 450px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 3px;

    .login_avatar {
      width: 130px;
      height: 130px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      background: #fff;
      border: 1px solid #eee;
      border-radius: 50%;
      box-shadow: 0 0 10px #ddd;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .login_form {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      .btns {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>